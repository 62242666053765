.hero {
  position: relative;
  z-index: var(--z-index-2);
  width: 100%;
  padding-top: calc(var(--nav-height) + var(--spacer-64));
  padding-bottom: 0;
  background-color: var(--color-grey-00);

  --tube-initial-scale: 0.92;

  @media (--md) {
    padding-top: calc(var(--nav-height) + var(--spacer-160));

    .accents,
    .titles,
    .portableTextTypes {
      grid-column: start / end;
    }

    .ctas {
      margin: auto;
      margin-top: var(--spacer-32);
    }

    .bodies {
      grid-column: 2 / span 10;
    }
  }

  @media (--lg) {
    .bodies {
      grid-column: 3 / span 8;
    }
  }
}

.container {
  composes: grid from '~g';
  position: relative;
}

.container.hasFloatingVideo {
  /* We're using --sm-only here before the specificity prevents us from overriding this value in other breakpoints */
  @media (--sm-only) {
    --hero-padding: 0;
  }
}

.textWrapper {
  composes: subgrid from '~g';
}

.modalVideoAndSecondaryCtasContainer {
  composes: subgrid from '~g';
  margin-top: var(--spacer-32);

  @media (--lg) {
    margin-top: 0;
  }
}

.tubeWrapper {
  z-index: var(--z-index-2);
  margin-top: var(--spacer-40);
  grid-column: start / end;
  pointer-events: none;

  @media (--md) {
    margin-top: var(--spacer-64);
  }
}

.tube1 {
  --tube-height: 11rem;
  transform: translateX(-50%);
  @media (--md) {
    --tube-height: 25rem;
    opacity: 0;
    transform: translateX(-50%) translateY(15%) scale(var(--tube-initial-scale));
  }
}

.tube2 {
  --tube-height: 25rem;
  top: 1rem;
  grid-column: start / end;
  transform: translateX(-50%);

  @media (--md) {
    top: 2rem;
    opacity: 0;
    transform: translateX(-50%) translateY(20%) scale(var(--tube-initial-scale));

    --tube-height: 60rem;
  }
}

.topTubeWrapper {
  position: relative;
}

.floatingVideoPreview {
  margin-top: var(--spacer-40);
  grid-column: start/end;

  --preview-video-aspect-ratio: 1.7;
  @media (--md) {
    margin-top: 0;
    grid-column: unset;
  }
}
