.container {
  position: relative;
  width: 100%;
  height: calc(var(--sequence-height, 0) * 1px);
  margin-top: calc(var(--section-overlap-top) * -1);
}

.fallbackImageContainer {
  display: none;
  width: 100%;
  height: 150%;

  @media (prefers-reduced-motion) {
    display: block;

    /* ensures that the sequence will only be hidden if there is a fallback image in place */
    + .sequenceContainer {
      display: none;
    }
  }
}

.fallbackImage {
  position: sticky;
  top: 0;
  width: 100%;
  height: 100vh;
}
