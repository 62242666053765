/* Variables */

$SmallReferenceWidthPercentage: 375 * 100%;
$SmallReferenceHeightPercentage: 877 * 100%;

/* Medium uses only VW as reference */
$MediumReferenceWidthPercentage: 960 * 100vw;

$LargeReferenceWidthPercentage: 1440 * 100%;
$LargeReferenceWidthPercentageVw: 1440 * 100vw;
$LargeReferenceHeightPercentage: 1985 * 100%;

@value parallaxFactors: 0.4 0.2 0.5 0.3 0.6 0.7 0.4 0.8 1;

@value smParallaxOffset: 15;
@value mdParallaxOffset: 5;
@value lgParallaxOffset: 10;
@value xlParallaxOffset: 10;

@define-mixin imageSmall $index, $top, $left {
  $i: calc(($index - 1) * 2 + 1);
  $j: calc(($index - 1) * 2 + 2);

  &:nth-child($i),
  &:nth-child($j) {
    top: calc($top / $SmallReferenceHeightPercentage);
    right: unset;
    left: calc($left / $SmallReferenceHeightPercentage);
  }
}
@define-mixin imageSmallAbsolute $index, $top, $left {
  $k: calc(($index - 1) * 2 + 1);
  $l: calc(($index - 1) * 2 + 2);

  &:nth-child($k),
  &:nth-child($l) {
    top: calc($top * 1px);
    right: unset;
    left: calc($left * 1px);
  }
}
@define-mixin imageMedium $index, $top, $left {
  $m: calc(($index - 1) * 2 + 1);
  $n: calc(($index - 1) * 2 + 2);

  &:nth-child($m),
  &:nth-child($n) {
    top: calc($top / $MediumReferenceWidthPercentage);
    right: unset;
    left: calc($left / $MediumReferenceWidthPercentage);
  }
}

@define-mixin imageLarge $index, $top, $left {
  $o: calc(($index - 1) * 2 + 1);
  $p: calc(($index - 1) * 2 + 2);

  &:nth-child($o),
  &:nth-child($p) {
    top: calc($top / $LargeReferenceHeightPercentage);
    right: unset;
    left: calc($left / $LargeReferenceWidthPercentage);
  }
}

.container {
  composes: grid from '~g';
  position: relative;
  padding-top: 26.5rem;
  padding-bottom: 46.9rem;

  @media (min-width: 600px) {
    padding-top: 29.6rem;
    padding-bottom: 54.9rem;
  }

  @media (--md) {
    padding-top: 2rem;

    /* Since medium is using VW, we want the height to match lowest placed card Y position */
    padding-bottom: calc(1159 / $MediumReferenceWidthPercentage);
  }
  @media (--lg) {
    padding-top: 5.5rem;
    padding-bottom: 167rem;
  }
}

.content {
  z-index: var(--z-index-1);

  @media (--md) {
    /* use the mixin value of the first image to top align the text box to the image */
    top: calc(17 / $MediumReferenceWidthPercentage);
    display: flex;

    /* calculate the height of the text box using the aspect ratio and mixin value for the first image */
    height: calc((371 / $MediumReferenceWidthPercentage) / var(--image-0-aspect-ratio));
    flex-direction: column;
    justify-content: center;
  }
  @media (--lg) {
    top: calc(0 / $LargeReferenceHeightPercentage);
    height: calc(min(calc(558 / $LargeReferenceWidthPercentageVw), 558px) / var(--image-0-aspect-ratio));
  }
}

.images {
  position: absolute;

  /* 1440px is the large design Figma artboard width */
  width: min(100%, 1440px);
  inset: 0 0 0 50%;
  transform: translateX(-50%);
}

.imageWrapper {
  position: absolute;
  width: 25.5rem;
  transition: transform 0.2s ease-out;

  /* Positioning using values from figma in fixed units */
  @mixin imageSmallAbsolute 1, 0, 23;
  @mixin imageSmallAbsolute 2, 47, 308;
  @mixin imageSmallAbsolute 3, 418, 500;
  @mixin imageSmallAbsolute 4, 483, -99;
  @mixin imageSmallAbsolute 5, 452, 194;
  @mixin imageSmallAbsolute 6, 698, 60;
  @mixin imageSmallAbsolute 7, 678, 378;

  /* had to add this breakpoint for ad */
  @media (min-width: 600px) {
    width: 27.1rem;

    /* Positioning using values from figma in fixed units */
    @mixin imageSmallAbsolute 1, 0, 122;
    @mixin imageSmallAbsolute 2, 60, 465;
    @mixin imageSmallAbsolute 3, 318, 696;
    @mixin imageSmallAbsolute 4, 544, -76;
    @mixin imageSmallAbsolute 5, 485, 338;
    @mixin imageSmallAbsolute 6, 756, 226;
    @mixin imageSmallAbsolute 7, 699, 644;
  }

  /* We double the nth number to take the glows into account */

  /* We need only 5 images to be displayed on mobile */
  &:nth-child(n + 15) {
    display: none;
  }

  @media (--md) {
    /* values from Figma file, to convert into VW */
    width: calc(371 / $MediumReferenceWidthPercentage);

    &:nth-child(n + 15) {
      display: block;
    }
    @mixin imageMedium 1, 17, 472;
    @mixin imageMedium 2, 337, 752;
    @mixin imageMedium 3, 406, 264;
    @mixin imageMedium 4, 542, -179;
    @mixin imageMedium 5, 658, 730;
    @mixin imageMedium 6, 778, 250;
    @mixin imageMedium 7, 887, -219;
    @mixin imageMedium 8, 1008, 680;
    @mixin imageMedium 9, 1159, 189;
  }

  @media (--lg) {
    /* values from Figma file to covnert into % */
    width: min(calc(558 / $LargeReferenceWidthPercentage), 558px);
    @mixin imageLarge 1, 0, 574;
    @mixin imageLarge 2, 290, 1220;
    @mixin imageLarge 3, 511, 428;
    @mixin imageLarge 4, 670, -268;
    @mixin imageLarge 5, 769, 1085;
    @mixin imageLarge 6, 1006, 375;
    @mixin imageLarge 7, 1161, -327;
    @mixin imageLarge 8, 1266, 1014;
    @mixin imageLarge 9, 1565, 311;
  }

  .imageShadow {
    z-index: var(--z-index-0);
  }
}

.image {
  position: relative;
  z-index: var(--z-index-1);
  overflow: hidden;
  border-radius: var(--border-radius-5);
  @media (--md) {
    border-radius: var(--border-radius-8);
  }

  @media (--lg) {
    /* We use px instead of rem to avoid a resize due to fluid typography on bigger resolutions */
  }
}

.imageElement {
  position: relative;
  z-index: var(--z-index-1);
  opacity: 0;
}

.imageGlow {
  z-index: var(--z-index-neg);
  overflow: visible;

  .glow {
    aspect-ratio: var(--aspect-ratio);
  }
}
