.heroTransitionV4 {
  position: relative;
  z-index: var(--z-index-neg);
  width: 100%;
}

.mediaContainer {
  composes: grid from '~g';
}

.mediaInner {
  display: flex;
  width: 110%;
  flex-direction: column;
  margin-left: -5%;
  grid-column: start / end;
  transform: scale(var(--scale));
  transform-origin: center top;
  will-change: transform;

  --start-transition: 0.1;
  --end-transition: 0.3;
  --clamped-progress: clamp(var(--start-transition), var(--hero-progress), var(--end-transition));
  --scale: calc((var(--clamped-progress) - var(--start-transition)) / (var(--end-transition) - var(--start-transition)) * (1 - 1.3) + 1.3);

  @media (--md) {
    --end-transition: 0.5;
    width: 100%;
    margin-left: 0;
  }

  &.gridOffset {
    width: 110%;
    margin-left: -5%;
  }
}

.media {
  position: relative;
}

.blurWrapper {
  @media (--md) {
    opacity: 1;
    visibility: visible;
  }
}
