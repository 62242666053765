.heroScreen {
  --content-wrapper-top: calc(var(--nav-height) + var(--banner-height, 0px) + var(--spacer-64));
  --transition-out-progress: 0;
  --opacity-progress: 0;
  padding-top: var(--content-wrapper-top);
  padding-bottom: 0;

  --covered-module-negative-bottom-margin: var(--spacer-48);
  @media (--md) {
    padding-bottom: var(--spacer-96);
    opacity: var(--opacity-progress);

    --content-wrapper-top: calc(var(--spacer-96) + var(--nav-height));
    --covered-module-negative-bottom-margin: var(--spacer-240);
  }
  @media (--lg) {
    --content-wrapper-top: calc(var(--nav-height) + var(--spacer-124));
    padding-bottom: var(--spacer-124);

    --covered-module-negative-bottom-margin: var(--spacer-320);
  }
}

.heroInner {
  display: flex;
  max-width: 144rem;
  flex-direction: column;
  padding-top: var(--spacer-16);
  margin: 0 auto;

  @media (--md) {
    padding-top: var(--spacer-32);
  }

  @media (--lg) {
    padding-top: var(--spacer-64);
  }
}

.content {
  width: 100%;
  padding: 0 var(--spacer-24);
  transform-origin: 50% -200%;

  @media (--md) {
    position: sticky;
    top: var(--content-wrapper-top);
    width: calc(var(--grid-computed-column-width) * 12 + var(--grid-gutter-size) * 11);
    align-self: var(--lockup-justify);
    padding: 0;
    margin: 0 var(--spacer-48);
    opacity: calc(1 - 3.5 * var(--transition-out-progress));
    transform: scale(calc(1 - 0.06 * var(--transition-out-progress))) translateY(calc(var(--transition-out-progress, 0) * -60vh));
  }
}

.mediaWrapper {
  width: 100%;
  opacity: 0;
  pointer-events: none;
}

.mediaContainer {
  position: relative;
  z-index: var(--z-index-1);
  display: flex;
  width: 100%;
  align-self: center;
  padding: var(--spacer-48) var(--spacer-6) 0;

  @media (--md) {
    width: auto;
    padding: var(--spacer-96) var(--spacer-48) 0;
  }

  @media (--lg) {
    padding: var(--spacer-124) var(--spacer-48) 0;
  }
}

.mediaContainerCropped {
  > div {
    overflow: unset;
    width: auto;
  }
}

.uiContainer {
  z-index: 2;
  overflow: hidden;
  width: 100%;
}

.uiContainerInner {
  position: relative;
  width: 100%;
  height: 100%;
}

.mediaSizeCropped {
  .media {
    width: auto;
    min-height: 42.2rem;
  }

  @media (--md) {
    height: auto;
  }
}

.uiMedia {
  width: 100%;
  height: 100%;
}

.media {
  width: 100%;
}
