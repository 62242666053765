/* That will supposedly always be the column the lowest */
$bottomPartOfMiddleColumnY: 50rem;
$aspectRatio: 4 / 3;

.heroImageWall {
  /* responsible for the opening of the hero image wall */
  --vertical-factor: 0.58;
  --content-wrapper-height: min(90rem - 2 * var(--nav-height), 100rem);
  --hero-image-width: 15.7rem;
  --hero-image-gutter: var(--spacer-12);
  --hero-image-aspect-ratio: calc(4 / 3);
  --hero-image-height: calc(var(--hero-image-width) / var(--hero-image-aspect-ratio));
  --image-wall-progress: 0;
  --entry-animation-progress: 0;

  /* middle bottom column y + number of images */
  /* stylelint-disable-next-line max-line-length */
  --max-bottom-element: calc($bottomPartOfMiddleColumnY * var(--vertical-factor) + var(--hero-image-height) + var(--hero-image-gutter));
  position: relative;
  height: calc(var(--max-bottom-element) * var(--height-ratio, 0.67) + (var(--content-wrapper-height) + var(--hero-image-height)) / 2);
  padding-top: var(--nav-height);

  --height-ratio: 0.73;

  @media (min-aspect-ratio: 6 / 10) {
    --height-ratio: 0.9;
  }

  @media (min-aspect-ratio: 15 / 10) {
    --height-ratio: 0.8;
  }

  @media (min-aspect-ratio: 17 / 10) {
    --height-ratio: 0.7;
  }

  @media (min-aspect-ratio: 17 / 10) and (min-width: 2100px) {
    --height-ratio: 0.8;
  }

  @media (min-aspect-ratio: 20 / 10) and (min-width: 2100px) {
    --height-ratio: 0.68;
  }

  @media (--md) {
    /* TODO: Handle vertical padding */
    --content-wrapper-height: min((100svh - 2 * var(--nav-height)), 100rem);
    --vertical-factor: 0.73;
    --hero-image-width: 24.4rem;
    --hero-image-gutter: var(--spacer-16);
  }

  @media (min-width: 960px) {
    --height-ratio: 0.85;
  }

  @media (--lg) {
    --vertical-factor: 1.05;
    --hero-image-width: 34.4rem;
    --hero-image-gutter: var(--spacer-24);
  }
}

.contentWrapper {
  composes: grid from '~g';
  position: relative;
  height: var(--content-wrapper-height);
  align-items: center;
}

.heroImageWall .wall {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  /* offset values to align on grid, need to play around with it a little bit */

  /* small & medium stays in percentages to avoid gaps */

  --first-column-x: -50.8rem;

  @media (--md) {
    --first-column-x: -77.8rem;
  }

  @media (--lg) {
    --first-column-x: -110.8rem;
  }
}

.heroImageWall .imageWrapper {
  position: absolute;
  width: var(--hero-image-width);
  backface-visibility: hidden;
  opacity: var(--entry-animation-opacity, 0);
  transform: translate3d(0, calc(((90rem + var(--image-wall-increment, 0) * 200%) * (1 - var(--normalized-entry-animation-progress, 0))) - (var(--normalized-image-wall-progress) * 100rem * var(--image-wall-column-ratio))), 0);
  will-change: transform;

  --normalized-entry-animation-progress: calc((var(--entry-animation-progress) / 2 + 0.5));

  /* increase canvas size to prevent cropping because these are too small */
  --mobile-canvas-scale: 2;
  --normalized-image-wall-progress: calc(var(--image-wall-progress) * 0.15);

  @media (--md) {
    transform: translate3d(0, calc(((100svh + var(--image-wall-increment, 0) * 200%) * (1 - var(--normalized-entry-animation-progress, 0))) - (var(--normalized-image-wall-progress) * 100rem * var(--image-wall-column-ratio))), 0);
  }
  @media (--lg) {
    --normalized-image-wall-progress: var(--image-wall-progress) * 0.4;
  }

  --base-top: calc(50% - var(--hero-image-height) / 2);
  --base-left: calc(50% - var(--hero-image-width) / 2);

  /* We display: none the images that are not visible on sm */
  &:nth-child(-n + 6),
  &:nth-last-child(-n + 8) {
    display: none;
    @media (--sm-only) {
      /* stylelint-disable-next-line declaration-no-important */
      transform: none !important;
    }
    @media (--md) {
      display: block;
    }
  }

  /* We display: none the images that are not visible on md */
  &:nth-child(-n + 2),
  &:nth-last-child(-n + 3) {
    display: none;
    @media (--sm-only) {
      /* stylelint-disable-next-line declaration-no-important */
      transform: none !important;
    }
    @media (--lg) {
      display: block;
    }
  }
}

.content {
  opacity: 0;

  .titles,
  .bodies {
    grid-column: start/ end;
    text-align: center;
  }

  .ctas {
    align-items: center;
    justify-content: center;
    grid-column: start/ end;

    @media (--md) {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--spacer-16);
    }
  }

  @media (--md) {
    .titles {
      grid-column: 3 / span 8;
    }

    .bodies {
      grid-column: 3 / span 8;
    }

    .titles,
    .bodies {
      text-align: center;
    }
  }

  @media (--lg) {
    .bodies {
      grid-column: 4 / span 6;
    }
  }
}

.imagesWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.imageWrapperInner {
  width: 100%;
  aspect-ratio: $aspectRatio;
  mask-image: var(--top-bottom-mask-10px);

  @media (--md) {
    mask-image: var(--top-bottom-mask-10px);
  }
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.glows {
  position: absolute;
  z-index: var(--z-index-neg);
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  @media (--md) {
    display: block;
  }
  @media (--sm-only) {
    /* stylelint-disable-next-line declaration-no-important */
    transform: none !important;
  }
}

.glow {
  z-index: var(--z-index-neg);
  aspect-ratio: $aspectRatio;

  --entry-animation-progress: 1;
}

$imgHeightAndGutter: var(--hero-image-height) + var(--hero-image-gutter);

$firstColX: var(--first-column-x);

/* TODO: Make a mixin out of it */

/* Column 1 */
$colX: calc($firstColX + (var(--hero-image-width) + var(--hero-image-gutter)) * 0);
$colY: calc(-30rem * var(--vertical-factor));
$colRatio: 0;

.heroImageWall .imageWrapper:nth-child(1) {
  --image-wall-increment: 0;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 0));
  left: calc(var(--base-left) + $colX);
}

.heroImageWall .imageWrapper:nth-child(2) {
  --image-wall-increment: 1;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 1));
  left: calc(var(--base-left) + $colX);
}

/* Column 2 */
$colX: calc($firstColX + (var(--hero-image-width) + var(--hero-image-gutter)) * 1);
$colY: calc(-50rem * var(--vertical-factor));
$colRatio: 0.4;

.heroImageWall .imageWrapper:nth-child(3) {
  --image-wall-increment: 0;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 0));
  left: calc(var(--base-left) + $colX);
}

.heroImageWall .imageWrapper:nth-child(4) {
  --image-wall-increment: 1;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 1));
  left: calc(var(--base-left) + $colX);
}

.heroImageWall .imageWrapper:nth-child(5) {
  --image-wall-increment: 2;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 2));
  left: calc(var(--base-left) + $colX);
}

.heroImageWall .imageWrapper:nth-child(6) {
  --image-wall-increment: 2;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 3));
  left: calc(var(--base-left) + $colX);
}

.heroImageWall .imageWrapper:nth-child(7) {
  --image-wall-increment: 2;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 4));
  left: calc(var(--base-left) + $colX);
}

/* Column 3 upper */
$colY: calc(-67rem * var(--vertical-factor));
$colX: calc($firstColX + (var(--hero-image-width) + var(--hero-image-gutter)) * 2);

$colRatio: 0.6;

.heroImageWall .imageWrapper:nth-child(8) {
  --image-wall-increment: 0;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 0));
  left: calc(var(--base-left) + $colX);
}

.heroImageWall .imageWrapper:nth-child(9) {
  --image-wall-increment: 1;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 1));
  left: calc(var(--base-left) + $colX);
}

/* Column 3 lower */
$colY: calc(41rem * var(--vertical-factor));

.heroImageWall .imageWrapper:nth-child(10) {
  --image-wall-increment: 0;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 0));
  left: calc(var(--base-left) + $colX);
}

.heroImageWall .imageWrapper:nth-child(11) {
  --image-wall-increment: 1;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 1));
  left: calc(var(--base-left) + $colX);
}

/* Column 4 upper */
$colY: calc(-77rem * var(--vertical-factor));
$colX: calc($firstColX + (var(--hero-image-width) + var(--hero-image-gutter)) * 3);

$colRatio: 0.8;

.heroImageWall .imageWrapper:nth-child(12) {
  --image-wall-increment: 0;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 0));
  left: calc(var(--base-left) + $colX);
}

.heroImageWall .imageWrapper:nth-child(13) {
  --image-wall-increment: 1;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 1));
  left: calc(var(--base-left) + $colX);
}

/* Column 4 lower */
$colY: calc($bottomPartOfMiddleColumnY * var(--vertical-factor));

.heroImageWall .imageWrapper:nth-child(14) {
  --image-wall-increment: 0;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 0));
  left: calc(var(--base-left) + $colX);
}

.heroImageWall .imageWrapper:nth-child(15) {
  --image-wall-increment: 1;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 1));
  left: calc(var(--base-left) + $colX);
}

/* Column 5 upper */
$colY: calc(-62rem * var(--vertical-factor));
$colX: calc($firstColX + (var(--hero-image-width) + var(--hero-image-gutter)) * 4);

$colRatio: 1.1;

.heroImageWall .imageWrapper:nth-child(16) {
  --image-wall-increment: 0;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 0));
  left: calc(var(--base-left) + $colX);
}

.heroImageWall .imageWrapper:nth-child(17) {
  --image-wall-increment: 1;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 1));
  left: calc(var(--base-left) + $colX);
}

/* Column 5 lower */
$colY: calc(44rem * var(--vertical-factor));

.heroImageWall .imageWrapper:nth-child(18) {
  --image-wall-increment: 0;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 0));
  left: calc(var(--base-left) + $colX);
}

.heroImageWall .imageWrapper:nth-child(19) {
  --image-wall-increment: 1;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 1));
  left: calc(var(--base-left) + $colX);
}

/* Column 6 */
$colY: calc(-48rem * var(--vertical-factor));
$colX: calc($firstColX + (var(--hero-image-width) + var(--hero-image-gutter)) * 5);

$colRatio: 0.9;

.heroImageWall .imageWrapper:nth-child(20) {
  --image-wall-increment: 0;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 0));
  left: calc(var(--base-left) + $colX);
}

.heroImageWall .imageWrapper:nth-child(21) {
  --image-wall-increment: 1;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 1));
  left: calc(var(--base-left) + $colX);
}

.heroImageWall .imageWrapper:nth-child(22) {
  --image-wall-increment: 2;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 2));
  left: calc(var(--base-left) + $colX);
}

.heroImageWall .imageWrapper:nth-child(23) {
  --image-wall-increment: 3;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 3));
  left: calc(var(--base-left) + $colX);
}

.heroImageWall .imageWrapper:nth-child(24) {
  --image-wall-increment: 4;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 4));
  left: calc(var(--base-left) + $colX);
}

/* Column 7 */
$colY: calc(-40rem * var(--vertical-factor));
$colX: calc($firstColX + (var(--hero-image-width) + var(--hero-image-gutter)) * 6);

$colRatio: 0.4;

.heroImageWall .imageWrapper:nth-child(25) {
  --image-wall-increment: 0;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 0));
  left: calc(var(--base-left) + $colX);
}

.heroImageWall .imageWrapper:nth-child(26) {
  --image-wall-increment: 1;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 1));
  left: calc(var(--base-left) + $colX);
}

.heroImageWall .imageWrapper:nth-child(27) {
  --image-wall-increment: 2;
  --image-wall-column-ratio: $colRatio;
  top: calc(var(--base-top) + calc($colY + ($imgHeightAndGutter) * 2));
  left: calc(var(--base-left) + $colX);
}
